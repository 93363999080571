import React from "react";

import {Container} from "reactstrap";

function Header({title, subtitle}) {
    return (
        <>
            <div
                className="page-main-header section-dark"
                style={{
                    minHeight: '50vh',
                    backgroundImage:
                        "url(" + require("assets/img/wallpaper-minecraft.jpg").default + ")",
                }}
            >
                <div className="filter"/>
                <div className="content-center">
                    <Container>
                        <h1 className="presentation-title text-center"
                            style={{fontSize: '2.5rem', marginTop: '100px'}}>{title}</h1>
                        <h2 className="presentation-subtitle text-center" style={{fontSize: '1.25rem'}}>{subtitle}</h2>
                    </Container>
                </div>
                <div
                    className="moving-clouds"
                    style={{
                        backgroundImage:
                            "url(" + require("assets/img/clouds.png").default + ")",
                    }}
                />
            </div>
        </>
    );
}

export default Header;
