import React from "react";

import {
    Container,
    Row,
    Col,
    Button,
} from "reactstrap";

function SectionCarousel() {

    return (
        <div className="section text-center">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="8">
                        <h2 className="title">Turbocube késako?</h2>
                        <h5 className="description">
                            Le serveur Turbocube reviens en nouvelle version <b>Minecraft 1.20</b>!<br/>
                            Il s'agit d'un serveur <b>moddé</b> en survie <b>Semi-RolePlay</b> ayant
                            un objectif communautaire. Rejoins le Discord pour en savoir plus:
                        </h5>
                        <br/>
                        <Button
                            className="btn-round btn"
                            color="info"
                            href="https://discord.gg/X4wqWhtK47"
                            target="_blank"
                        >
                            Lien Discord
                        </Button>
                    </Col>
                </Row>
                <br/>
                <br/>
                <Row>
                    <Col md="3">
                        <div className="info">
                            <div className="icon icon-info">
                                <i className="nc-icon nc-map-big"/>
                            </div>
                            <div className="description">
                                <h4 className="info-title" style={{margin: '0 0 20px 0'}}>Live map</h4>
                                <p className="description">
                                    Une map mise à jour <b>en direct</b> de chaques <b>dimensions</b>&nbsp;
                                    et différentes <b>villes</b> de Turbocube
                                </p>
                                {/*<Button
                                    className="btn-link btn"
                                    color="info"
                                    href="https://discord.gg/X4wqWhtK47"
                                    target="_blank"
                                >
                                    Voir plus
                                </Button>*/}
                            </div>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="info">
                            <div className="icon icon-info">
                                <i className="nc-icon nc-app"/>
                            </div>
                            <div className="description">
                                <h4 className="info-title" style={{margin: '0 0 20px 0'}}>Semi-RP</h4>
                                <p>
                                    Un serveur <b>moddé</b>, nouvelle génération de map,&nbsp;
                                    des <b>skills</b> et des <b>métiers</b> et bien plus encore!
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="info">
                            <div className="icon icon-info">
                                <i className="nc-icon nc-sun-fog-29"/>
                            </div>
                            <div className="description">
                                <h4 className="info-title" style={{margin: '0 0 20px 0'}}>Communautaire</h4>
                                <p>
                                    Un serveur Discord actif et <b>connecté à Minecraft</b>,&nbsp;
                                    les messages y sont fusionné entres les deux
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="info">
                            <div className="icon icon-info">
                                <i className="nc-icon nc-laptop"/>
                            </div>
                            <div className="description">
                                <h4 className="info-title" style={{margin: '0 0 20px 0'}}>Serveur</h4>
                                <p>
                                    VPS sous Ubuntu <b>16Go de RAM</b> host chez <b>OVH</b> et hébergé à <b>Strasbourg</b>
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default SectionCarousel;
