import React from "react";

import {Row, Container} from "reactstrap";

function Footer() {
    return (
        <footer className="footer footer-black footer-white">
            <Container>
                <Row>
                    <nav className="footer-nav">
                        <ul>
                            <li>
                                <a href="/">
                                    Turbocube
                                </a>
                            </li>
                            {/*<li>
                                <a href="/settings">
                                    Settings
                                </a>
                            </li>*/}
                            <li>
                                <a href="/inventory">
                                    Inventaire
                                </a>
                            </li>
                            <li>
                                <a href="http://mc.turbocube.fr:8123/" target="_blank" rel="noreferrer">
                                    Map
                                </a>
                            </li>
                            <li>
                                <a href="/about-us">
                                    A propos
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <div className="credits ml-auto">
            <span className="copyright">
              © {new Date().getFullYear()}, made with{" "}
                <i className="fa fa-heart heart"/> by Alexis Knob
            </span>
                    </div>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;
