import React from "react";
import Countdown from 'react-countdown';

import {Container} from "reactstrap";

function Waiting({ openDate }) {
    return (
        <div
            className="page-header section-dark"
            style={{
                backgroundImage:
                    "url(" + require("assets/img/wallpaper-minecraft.jpg").default + ")",
            }}
        >
            <div className="filter"/>
            <div className="content-center">
                <Container>
                    <div className="title-brand">
                        <h1 className="presentation-title">Turbocube</h1>
                    </div>
                    <h2 className="presentation-subtitle text-center">
                        Le serveur ouvre dans <b style={{fontWeight: 800}}> <Countdown date={openDate} /> </b>
                    </h2>
                </Container>
            </div>
            <div
                className="moving-clouds"
                style={{
                    backgroundImage:
                        "url(" + require("assets/img/clouds.png").default + ")",
                }}
            />
        </div>
    );
}

export default Waiting;
